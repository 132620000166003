import React, { Component } from 'react';
import { Table, Col, Row    } from 'antd';

interface dataProps {
  data :   any , 
  claimType: any,
  postedDate : any,
  toatlAmount : any,
  claim_id : any
}

const columns = [
  
//   {
//     title: '',
//     dataIndex: 'index',
//     value:'*'
//   },
  {
    title: 'Emp#',
    dataIndex: 'emp_no',
    }, {
		title: 'Emp Name',
		dataIndex: 'name',
		width: '30%',
	  },
	  {
		title: 'Claim ID',
		dataIndex: 'id',
	  },
    {
      title: 'Claim Date',
      dataIndex: 'claimedDate',
      render: (claimedDate : any) => claimedDate.split("T")[0]
    },
   
    {
      title: 'SAP Doc',
	  dataIndex: 'sapdocRefID',
	  
    }, 
    {
      title: 'Total Amount',
	  dataIndex: 'amount',
	  align: 'right' as 'right',
      render: (totalAmount : any) => "Rs. "+(Math.round( totalAmount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } 
  ];

export default class ComponentToPrint extends React.Component<dataProps> {
    render() {
      return (
          <div style ={{ margin : 10}}>
            <h1 style ={{ paddingLeft : 10 , paddingTop:10}}>LION BREWERY (CEYLON) PLC  </h1>
			<h2 style ={{ paddingLeft : 10 , paddingTop:4}}>{this.props.claimType}  </h2>
			<h3 style ={{ paddingLeft : 10 , paddingTop:3 , fontWeight:300}}>Claim Type - {this.props.claimType}</h3>
			<h3 style ={{ paddingLeft : 10 , paddingTop:1, fontWeight:300}}>Posted Date - {this.props.postedDate}</h3>
			<h3 style ={{ paddingLeft : 10 , paddingTop:1 , fontWeight:300}}>Payment Sheet ID - {this.props.claim_id}</h3>
               
             <Table pagination={ false} style={{marginTop:20}}  columns={columns} dataSource={ this.props.data} />
			 <Row style={{  width:"100%" , height:60 , padding :10 }}>

			            <Col span="19" >
                             <h2   > Total Amount </h2>
                        </Col>
                        <Col span="5" > <h2 style={{ fontSize: 18 , textAlign:"right" }} >  {"Rs. "+(Math.round(  this.props.toatlAmount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </h2></Col>
			 </Row>
        </div>
      );
    }
  }