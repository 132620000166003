import React, {  Component } from 'react';
import { Table,  Typography , Row, Col,  Radio,  Spin , Button , message, Descriptions, Badge} from 'antd'; 
import { connect } from 'react-redux';
 
import { FileTextOutlined } from '@ant-design/icons';
import { Elements } from '../components/Dashboard'
import  ComponentToPrint   from './ComponentToPrint'
import ReactToPrint from "react-to-print";
import { DashboardActions } from '../repos/DashboardRepo';
import './style.css'; 
import generateCalendar from 'antd/lib/calendar/generateCalendar';
import { any } from '@hapi/joi';
import { texts } from '../texts.config';
const {   Text } = Typography;
 

const CardMediaSpans = {
    xl: 6,
    xs: 24,
    sm: 24,
    lg: 12,
}
const apiName = [ "SaveMedicalClaims", "SaveClubMembershsipClaims" ,"SaveFuelClaims" ,"SavePettyCashClaims" , "SaveMEClaims"  ]

const getColor = (i: number) => {
    switch (i) {
        case 0: return '#4C56BA'
        case 1: return '#29275F'
        case 2: return '#F64B53'
        case 3: return '#929CB7'
        default: return '#0B3C49'
    }
}
 
const columns = [
    {
      title: 'Claim ID',
      dataIndex: 'id',
    },
    {
      title: 'Employee No',
      dataIndex: 'emp_no',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    }, 
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount : any) => "Rs. "+(Math.round( amount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } 
  ];

  const zeroPad = (num : any, places : any) => String(num).padStart(places, '0')
  var selectedItems :  any[] = []; 
  var api :  String = ""; 
  
 
class PaymentSheet extends React.Component<any, any> {  
    componentRef!: Component<any, {}, any> | Element | null;
    constructor(props: any) {
        super(props); 
        this.state = {
            isVisible : false,
             loading : false,
             selectedRowKeys: [],
             value : 0,
             totalAmount : 0,
             pettyCashSummary : {},
             isPettyCash : false
            
        }
    }
 

    componentDidMount() {
        this._getData(0)
      }
    
      _getData = async (status : any) => {
        await this.props.getPettyCashSummary();
        this.setState({
          pettyCashSummary : this.props.DashboardRepo.pettyCashSummary
        })
        api = apiName[status]

        switch(status) {
           
          
          case 0: 
            await this.props.getMedicalClaims();  
            this.geretateDataset("medicalClaimId" ,"MC")
            break;
           case 1: 
            await this.props.getClubClaims();  
            this.geretateDataset("clubMembershipClaimId","CM")
            break;
            case 2:
              await this.props.getFuelClaims(); 
              this.geretateDataset("fuelClaimId","FC")
            break;
            case 3:
              await this.props.getPettyCashClaims(); 
              this.geretateDataset("pettyCashClaimId","PC")
            break;
            case 4:
              await this.props.getMEClaims(); 
              this.geretateDataset("meclaimId","ME")
            break; 
          
        }
            
      }

      geretateDataset(claimType : any , code : any){

        if(claimType == "pettyCashClaimId"){
          this.setState({
            isPettyCash : true
          })
          
        }
        else{
          this.setState({
            isPettyCash : false
          })
        }
       
        var detail : any[] = [];
        this.props.DashboardRepo.claims.forEach(function (data: any) { 
 
          detail.push({
            key:  data[claimType],
            id: code+'-' +  zeroPad(data[claimType], 6)  ,
            emp_no: data.employeeNumber,
            name: data.employeeName,
            amount: data.totalAmount,
          });
          
      });
      this.setState({claimHistory : detail , loading : false })
      }

     
    
      onSelectChange = ( selectedRowKeys : any)=> {
        
         var amount = 0;
         selectedRowKeys.forEach( (data: any) => {
           amount += this.state.claimHistory.find((claim: { key: string; }) => claim.key === data).amount ;
              
        }) 
       
        this.setState({ selectedRowKeys , totalAmount : amount});
     
      };

      Submit = async () =>{
        if(this.state.selectedRowKeys.length > 0){
          this.setState({loading : true})
          var res = await this.props.sendToSap(api ,this.state.totalAmount , this.state.selectedRowKeys ); 

          if(res == texts.SEND_TO_SAP_SUCCESS){
            message.success(res);
            this.setState({loading : false , selectedRowKeys: []});
            this._getData(this.state.value);
          }
          else{
            message.error(res);
            this.setState({loading : false , selectedRowKeys: []});
          } 
        }
        else{
          message.error("Please Select Claims")
        }
      }
 

       
    onFilterChange = (e : any) => {  
      this.setState({
            value: e.target.value,
            loading : true
          });
        
          this._getData(e.target.value)
      
      }

    
 
      

   render() {
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
        return (
            <div>
                 
                 <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                
                
                <Row>
                    <Col style={{ padding: 10, marginTop: 4 }} span={24} >
                        <Row>
                        <Col span="4" >
                        <Text style={{ fontSize: 18 }} strong>Approved Claims  </Text>
                        </Col>
                        <Col span="16" >
                        <Radio.Group style={{marginLeft : 60}} onChange={this.onFilterChange}  value={this.state.value}>
                            <Radio value={0}>Medical Claim</Radio>
                            <Radio value={1}>Club Membership</Radio> 
                            <Radio value={2}>Fuel Claim</Radio>
                            <Radio value={3}>Petty Cash</Radio>
                            <Radio value={4}>Me Claim</Radio>
                        </Radio.Group>
                        
                        </Col>
                       
                        <Col span="4" style={{ display: 'flex', justifyContent: 'flex-end'  }}>
                        <div>
                          <Row>
                        <Button onClick={this.Submit} color="#875ec4"> Send To SAP</Button>
                                 </Row>
                            </div>
                              {/* <Button type="primary">Submit All</Button> */}
                       
                        </Col>
                         
                     </Row>
                   {this.state.isPettyCash && <Descriptions bordered style={{marginTop : 20}}>
                            <Descriptions.Item label="Cash Float">{this.state.pettyCashSummary.cashFloat}</Descriptions.Item>
                            <Descriptions.Item label="Cash In Hand">{this.state.pettyCashSummary.cashInHand}</Descriptions.Item>
                            <Descriptions.Item label="Paid Amount IOU">{this.state.pettyCashSummary.paidAmountIOU}</Descriptions.Item> 
                            <Descriptions.Item label="Paid Amount Petty Cash">{this.state.pettyCashSummary.paidAmountPettyCash}</Descriptions.Item>
                            <Descriptions.Item label="Total Paid Amount">{this.state.pettyCashSummary.totalPaidAmount}</Descriptions.Item>
                            
                          </Descriptions> }
                        <Row style={{ marginTop: 20 }}>
                         
                        <div style={{ marginBottom: 16 , width : "100%"}}> 
                          <span style={{ marginLeft: 8 }}>
                              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                          </span> 
                          <Table pagination={{ pageSize: 25 }}  rowSelection={rowSelection} columns={columns} dataSource={ this.state.claimHistory} />
                        </div>
                        
                        </Row>
                    </Col>
                     
                     
                </Row>
          
                
               </Spin>
            </div>
        )
    }
}

const WrappedDashboardView = PaymentSheet;
const withDashboardRepo = connect(((state: any) => state), DashboardActions)(WrappedDashboardView);
export default withDashboardRepo;


