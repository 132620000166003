export class RouteConfig{
    static root = '/'

    static PROTECTED = '/protected'
    static PROTECTED_ROOT = '/protected/'
    static PROTECTED_MAIN_DASHBOARD = '/protected/dashboard' 
	static PROTECTED_PAYMENT_SHEET = '/protected/PaymentSheet'
	static PROTECTED_ERROR_LOG = '/protected/errorLogs' 
	
    static PROTECTED_VIEW_PAYMENT_SHEET = '/protected/view-payment-sheet/:id'  

    

    static LOGIN = '/'
}

//this applies to breadcrumbs
export const rootComponent = "Home"
export const rootIcon = "home"

