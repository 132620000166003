import React, {  Component } from 'react';
import { Table,  Typography , Row, Col,  Radio,  Spin  ,Tag, Button } from 'antd'; 
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import {  FileTextOutlined } from '@ant-design/icons';
import { Elements } from '../components/Dashboard'
import  ComponentToPrint   from './ComponentToPrint'
import ReactToPrint from "react-to-print";
import { DashboardActions } from '../repos/DashboardRepo';
import './style.css'; 
const {   Text } = Typography;
const { Column } = Table;
var claimCode = "";
const CardMediaSpans = {
    xl: 6,
    xs: 24,
    sm: 24,
    lg: 12,
}


const getColor = (i: number) => {
    switch (i) {
        case 0: return '#4C56BA'
        case 1: return '#29275F'
        case 2: return '#F64B53'
        case 3: return '#929CB7'
        default: return '#0B3C49'
    }
}
 
const columns = [
  
  {
    title: 'Id',
    dataIndex: 'index',
  },
    {
      title: 'claimEndDate',
      dataIndex: 'claimEndDate',
    },
    {
      title: 'claimStartDate',
      dataIndex: 'claimStartDate',
    },
    {
      title: 'postedDate',
      dataIndex: 'postedDate',
    }, 
    {
      title: 'totalAmount',
      dataIndex: 'totalAmount',
    } 
  ];
 
 
  
 
class Dashboard extends React.Component<any, any> {  
    componentRef!: Component<any, {}, any> | Element | null;
    constructor(props: any) {
        super(props); 
        this.state = {
            isVisible : false,
             loading : true,
             selectedRowKeys: [],
             value : 0
            
        }
    }
 

    async componentDidMount() {
        await this.props.getSummaryData() 
        this._getData(0)
      }
    
      async  _getData(status : any) {
   

        switch(status) {
          // case 0:
          //   await this.props.getAllPaymentSheets("GetAllMedicalPaymentSheets");  
          //   break;
          //  case 1:
          //   await this.props.getAllPaymentSheets("GetAllClubMembershipPaymentSheets");  
          //   break;
          //   case 2:
          //     await this.props.getAllPaymentSheets("GetAllFuelPaymentSheets"); 
          //   break;
          //   case 3:
          //     await this.props.getAllPaymentSheets("GetAllPettyCashPaymentSheets"); 
          //   break;
          //   case 4:
          //     await this.props.getAllPaymentSheets("GetAllMEPaymentSheets"); 
          //   break; 

          case 0:
            claimCode = "mc"
            await this.props.getAllPaymentSheets("GetAllMedicalPaymentSheets");  
            break;
           case 1:
            claimCode = "cm"
            await this.props.getAllPaymentSheets("GetAllClubMembershipPaymentSheets");  
            break;
            case 2:
              claimCode = "fc"
              await this.props.getAllPaymentSheets("GetAllFuelPaymentSheets"); 
            break;
            case 3:
              claimCode = "pc"
              await this.props.getAllPaymentSheets("GetAllPettyCashPaymentSheets"); 
            break;
            case 4:
              claimCode = "me"
              await this.props.getAllPaymentSheets("GetAllMEPaymentSheets"); 
            break; 
          
        }
          
        this.setState({claimHistory : this.props.DashboardRepo.paymentSheets , loading : false })
      }

     
 

       
    onFilterChange = (e : any) => {  
      this.setState({
            value: e.target.value,
            loading : true
          });
        
          this._getData(e.target.value)
      
      }
 
   render() {
    const { loading, selectedRowKeys } = this.state;
     
        return (
            <div>
                 
                 <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                <Row gutter={[10, 10]}>
                    {
                        (this.props.DashboardRepo.summaryData && this.props.DashboardRepo.summaryData.length > 0) && this.props.DashboardRepo.summaryData.map((key: any, i: number) => <Col {...CardMediaSpans}>
                            <Elements.ValueCard
                                header={key.title}
                                backgroundColor={getColor(i)}
                                icon={<FileTextOutlined />}>
                                {key.value}
                            </Elements.ValueCard>
                        </Col>)
                    }
                </Row>
                <Row style={{ height: 12, backgroundColor: "#f0f0f0", marginLeft: -25, marginTop: 20, marginRight: -25 }}></Row>
                <Row>
                    <Col style={{ padding: 10, marginTop: 4 }} span={24} >
                        <Row>
                        <Col span="4" >
                        <Text style={{ fontSize: 18 }} strong>Payment Sheets  </Text>
                        </Col>
                        <Col span="16" >
                        <Radio.Group style={{marginLeft : 60}} onChange={this.onFilterChange}  value={this.state.value}>
                            <Radio value={0}>Medical Claim</Radio>
                            <Radio value={1}>Club Membership</Radio> 
                            <Radio value={2}>Fuel Claim</Radio>
                            <Radio value={3}>Petty Cash</Radio>
                            <Radio value={4}>ME Claim</Radio>
                        </Radio.Group>
                        </Col>
                        <Col span="4" style={{ display: 'flex', justifyContent: 'flex-end'  }}>
                        <div>
                        <Link  to={`paymentSheet`}> 
                                            <Button color="#875ec4"> Generate New Payment Sheet</Button>  
                                       </Link>
                            </div>
                              {/* <Button type="primary">Submit All</Button> */}
                       
                        </Col>
                         
                     </Row>
                     {this.state.claimHistory &&  <Table style={{ marginTop: 26, padding: 5 }} dataSource={this.state.claimHistory.reverse() }> 
                    <Column title="ID" key="id"  dataIndex="id"  render={(value, item, index) => (  value)}  />
                    <Column  title="End Date"  dataIndex="claimEndDate" key="claimEndDate"  render={(value, item, index) => (  value.split("T")[0]  )}/>
                    <Column   title="Started Date" dataIndex="claimStartDate"  key="claimStartDate"  render={(value, item, index) => (  value.split("T")[0] )}/>
                    <Column  title="Posted Date" dataIndex="postedDate"  key="postedDate"  render={(value, item, index) => (  value.split("T")[0]  )}/>
                    <Column title="Amount " dataIndex="totalAmount" key="totalAmount" render={(value, item , index) => ("Rs. " + (Math.round(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}/>
                     
                    <Column
                            title="Action"
                            key="index"
                            render={(value, item, index) => (
                                <div>
                                      <Link  to={`view-payment-sheet/${claimCode +"_"+value.id+"_"+value.postedDate.split("T")[0]}`}> 
                                            <span>
                                            <a > <Tag color="#875ec4"> View</Tag> </a>
                                            </span>
                                       </Link>
                                </div>
                            )}
                            />
                </Table> }
                         
                    </Col>

                    
                     
                     
                </Row>
          
                
               </Spin>
            </div>
        )
    }
}

const WrappedDashboardView = Dashboard;
const withDashboardRepo = connect(((state: any) => state), DashboardActions)(WrappedDashboardView);
export default withDashboardRepo;


