import React, { Component } from 'react';
import { Upload, Button, Col, Row, Card } from 'antd'
import prettyBites from 'pretty-bytes'
import { RcFile } from 'antd/lib/upload/interface';
import { MinusCircleFilled, FileOutlined, PaperClipOutlined, InboxOutlined } from '@ant-design/icons'

const acceptsToAcceptString = (accepts: string[]): string => accepts.reduce((pre: string, cur: string) => pre + "," + cur)
const acceptsToAcceptRegex = (accepts: string[]): string => "(\\" + accepts.reduce((pre: string, cur: string) => pre + ")|(\\" + cur) + ")"

export interface FileDataMapper {
    file: string,
    comment: string,
    url: string
}

export interface UploaderState {
    data: Array<FileDataMapper>,
    files: Array<RcFile>
}

export const defaultImageExtensions: Array<AcceptTypes> = [".png", ".jpeg", ".jpg", ".PNG", ".JPG", ".JPEG", ".doc", ".docx", ".docm", ".dotm", ".dotx"]

type AcceptTypes = string | ".png" | ".jpeg" | ".jpg" | ".PNG" | ".JPG" | ".JPEG" | ".doc" | ".docx" | ".docm" | ".dotm" | ".dotx"

const defaultHeaderMessage = "Click or drag file to this area to upload"
const defaultMessage = "Support for a single or bulk upload."

interface UploaderProps<T> {
    defaultValue?: T
    message?: string
    headerMessage?: string
    accept: Array<AcceptTypes>
}

interface BaseUploaderProps extends UploaderProps<RcFile> {
    files?: Array<RcFile>
    multiple?: boolean
    renderPreview: () => React.ReactElement
    onFileRemove: (file: RcFile) => void
    onFileUpload: (file: RcFile) => void
}

class Uploader extends Component<BaseUploaderProps> {
    uploadProps: {}
    regex: RegExp
    constructor(props: any) {
        super(props);
        this.regex = new RegExp(acceptsToAcceptRegex(this.props.accept));
        this.uploadProps = {
            onRemove: (file: RcFile) => {
                this.props.onFileRemove(file);
            },
            beforeUpload: (file: RcFile) => {
                if (file.name.match(this.regex))
                    this.props.onFileUpload(file);
                return false;
            },
            accept: acceptsToAcceptString(this.props.accept),
            showUploadList: false,
            multiple: this.props.multiple,
        }
    }

    render() {
        return (
            <Col flex="auto" span={24}>
                <Row>
                    <Col flex="auto">
                        <Upload.Dragger {...this.uploadProps}>
                            {this.props.children}
                        </Upload.Dragger>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    {
                        this.props.renderPreview()
                    }
                </Row>
            </Col>
        )
    }
}

interface SingleFileUploaderProps extends UploaderProps<RcFile> {
    onChange: (file: RcFile | undefined) => void
}

interface SingleFileUploaderState {
    file?: RcFile
}

export class SingleFileUploader extends Component<SingleFileUploaderProps, SingleFileUploaderState>{

    constructor(props: any) {
        super(props);
        this.onUpload = this.onUpload.bind(this);
        this.onRemove = this.onRemove.bind(this)
        this.renderFilePreview = this.renderFilePreview.bind(this)
        this.state = {
            file: this.props.defaultValue
        }
    }

    onRemove() {
        this.setState((prevState: SingleFileUploaderState) => {
            prevState.file = undefined
            return prevState
        }, () => this.props.onChange(this.state.file))
    }

    onUpload(file: RcFile) {
        this.setState((prevState: SingleFileUploaderState) => {
            prevState.file = file
            return prevState
        }, () => this.props.onChange(this.state.file))
    }

    renderFilePreview() {
        if (this.state.file)
            return (
                <Col flex="auto">
                    <FilePreview file={this.state.file} onRemove={this.onRemove} />
                </Col>
            )
        return <Col></Col>
    }

    render() {
        return (
            <Uploader
                accept={this.props.accept}
                onFileRemove={this.onRemove}
                onFileUpload={this.onUpload}
                renderPreview={this.renderFilePreview}
                multiple={false}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    {this.props.headerMessage ? this.props.headerMessage : defaultHeaderMessage}
                </p>
                <p className="ant-upload-hint">
                    {this.props.message ? this.props.message : defaultMessage}
                </p>
            </Uploader>
        )
    }
}

export interface MultipleFileUploaderState extends UploaderState {
    files: Array<RcFile>
}

export interface MultipleFileUploaderProps extends UploaderProps<RcFile[]> {
    onChange: (files: UploaderState) => void
}


export class MultipleFileUploader extends Component<MultipleFileUploaderProps, MultipleFileUploaderState>{
    constructor(props: any) {
        super(props);
        this.onUpload = this.onUpload.bind(this);
        this.onRemove = this.onRemove.bind(this)
        this.onPreviewChange = this.onPreviewChange.bind(this)
        this.renderFilePreview = this.renderFilePreview.bind(this)
        this.state = {
            files: this.props.defaultValue ? this.props.defaultValue : [],
            data: []
        }
    }

    onRemove(file: RcFile) {
        this.setState((prevState: MultipleFileUploaderState) => ({
            files: prevState.files.filter((data: RcFile) => file.name !== data.name),
            data: prevState.data.filter((data: FileDataMapper) => file.name !== data.file)
        }), () => this.props.onChange(this.state))
    }

    onUpload(file: RcFile) {
        if (this.state.files.filter((_file: RcFile) => _file.name == file.name).length == 0)
            this.setState((prevState: MultipleFileUploaderState) => {
                prevState.files.push(file);
                prevState.data.push({
                    file: file.name,
                    comment: "",
                    url: ""
                })
                return prevState
            }, () => this.props.onChange(this.state))
    }

    onPreviewChange(data: FileDataMapper) {
        this.setState((prevState: MultipleFileUploaderState) => ({
            data: prevState.data.map((file: FileDataMapper) => (data.file === file.file) ? data : file)
        }), () => this.props.onChange(this.state))
    }

    renderFilePreview() {
        if (this.state.files)
            return (
                <Col flex="auto">
                    {
                        this.state.files.map((file: RcFile) => <MultipleFilePreview key={file.name} file={file} onChange={this.onPreviewChange} onRemove={this.onRemove} />)
                    }
                </Col>
            )
        return <Col></Col>
    }

    render() {
        return (
            <Uploader
                accept={this.props.accept}
                onFileRemove={this.onRemove}
                onFileUpload={this.onUpload}
                renderPreview={this.renderFilePreview}
                multiple={true}>
                <p className="ant-upload-drag-icon">
                    <PaperClipOutlined />
                </p>
                <p className="ant-upload-text">
                    {this.props.headerMessage ? this.props.headerMessage : defaultHeaderMessage}
                </p>
                <p className="ant-upload-hint">
                    {this.props.message ? this.props.message : defaultMessage}
                </p>
            </Uploader>
        )
    }
}

interface FilePreviewProps {
    file: RcFile
    onRemove: (file: RcFile) => void
}

class FilePreview extends Component<FilePreviewProps, any>{
    render() {
        return (
            <Card >
                <Row align="middle" gutter={10}>
                    <Col>
                        <FileOutlined style={{ fontSize: 40 }} />
                    </Col>
                    <Col flex="auto">
                        <Col style={{ marginBottom: 10 }}>
                            <Row className="file-upload-file-name">
                                {this.props.file.name}
                            </Row>
                            <Row className="file-upload-file-size">
                                {prettyBites(this.props.file.size)}
                            </Row>
                        </Col>
                    </Col>
                    <Col>
                        <Button
                            type="link"
                            onClick={() => this.props.onRemove(this.props.file)}>
                            <MinusCircleFilled />
                        </Button>
                    </Col>
                </Row>
            </Card>
        )
    }
}

interface MultipleFilePreviewProps {
    file: RcFile
    onRemove: (file: RcFile) => void
    onChange: (data: FileDataMapper) => void
}

class MultipleFilePreview extends Component<MultipleFilePreviewProps, any>{
    constructor(props: any) {
        super(props);
        this.onComment = this.onComment.bind(this)
        this.onUrl = this.onUrl.bind(this)
        this.state = {
            comment: "",
            url: ""
        }
    }

    onComment(text: string) {
        this.setState({
            comment: text
        })
        this.props.onChange({
            file: this.props.file.name,
            comment: text,
            url: this.state.url
        })
    }

    onUrl(text: string) {
        this.setState({
            url: text
        })
        this.props.onChange({
            file: this.props.file.name,
            comment: this.state.comment,
            url: text
        })
    }
    render() {
        return (
            <Card style={{ marginBottom: 10 }}>
                <Row align="middle" gutter={10}>
                    <Col>
                        <FileOutlined style={{ fontSize: 40 }} />
                    </Col>
                    <Col flex="auto">
                        <Col style={{ marginBottom: 10 }}>
                            <Row className="file-upload-file-name">
                                {this.props.file.name}
                            </Row>
                            <Row className="file-upload-file-size">
                                {prettyBites(this.props.file.size)}
                            </Row>
                            {/* <Row style={{ padding: 10, paddingLeft: 0 }}>
                                <Input.TextArea rows={1} placeholder="Comment" onChange={({ target: { value } }) => this.onComment(value)} />
                            </Row> */}
                            {/* <Row style={{ padding: 10, paddingLeft: 0 }}>
                                <Input placeholder="File link" onChange={({ target: { value } }) => this.onUrl(value)} />
                            </Row> */}
                        </Col>
                    </Col>
                    <Col>
                        <Button
                            type="link"
                            onClick={() => this.props.onRemove(this.props.file)}>
                            <MinusCircleFilled />
                        </Button>
                    </Col>
                </Row>
            </Card>
        )
    }
}