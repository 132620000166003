import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = { tagCardData: null,  pettyCashSummary: null,   paymentSheetData: null, paymentSheets: null, claims : null, summaryData: null , medical_claims : null ,fuel_claims : null, club_claims : null,pettycash_claims : null ,me_claims : null }, action: { type: DashboardActionTypes, payload: any }) => {
    switch (action.type) {
        case DashboardActionTypes.GET_ALL_TAG_CARD_DATA: {
            return { ...state, tagCardData: action.payload }
        } 
        case DashboardActionTypes.GET_USER_SUMMARY: {
            return { ...state, summaryData: action.payload }
        }  
        case DashboardActionTypes.GET_MEDICAL_CLAIMS: {
            return { ...state, medical_claims: action.payload }
        }   
        case DashboardActionTypes.GET_FUEL_CLAIMS: {
            return { ...state, fuel_claims: action.payload }
        } 
        case DashboardActionTypes.GET_CLUB_CLAIMS: {
            return { ...state, club_claims: action.payload }
        } 
        case DashboardActionTypes.GET_PETTY_CASH_CLAIMS: {
            return { ...state, pettycash_claims: action.payload }
        } 
        case DashboardActionTypes.GET_ME_CLAIMS: {
            return { ...state, me_claims: action.payload }
        }   

        case DashboardActionTypes.GET_CLAIMS: {
            return { ...state,  claims: action.payload }
        } 
        case DashboardActionTypes.GET_PAYMENT_SHEETS: {
            return { ...state,  paymentSheets: action.payload }
        }
        case DashboardActionTypes.GET_PAYMENT_SHEET_DATA: {
            return { ...state,  paymentSheetData: action.payload }
        }
        case DashboardActionTypes.GET_PETTY_CASH_SUMMARY: {
            return { ...state,  pettyCashSummary: action.payload }
        }
 
        

        
        default: return state;
    }
}

export const DashboardActions = (dispatch: any) => ({
 

    getSummaryData: async () => {
        try {
            //GetClaimCountEmployeeWise 
             const res = await lionUserManager.authenticationManager.get("/esb/Claims/GetAllPendingClaimsCounts"); 
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_USER_SUMMARY,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },


    getMedicalClaims: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/esb/Claims/GetAllMedicalClaimsByStatus?Status=Pending"); 
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_CLAIMS,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getFuelClaims: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/esb/Claims/GetAllFuelClaimsByStatus?Status=Pending"); 
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_CLAIMS,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getClubClaims: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/esb/Claims/GetAllClubMembershipClaimsByStatus?Status=Pending");
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_CLAIMS,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getMEClaims: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/esb/Claims/GetAllMEClaimsByStatus?Status=Pending");
 
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_CLAIMS,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getPettyCashClaims: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/esb/Claims/GetAllPettyCashClaimsByStatus?Status=Pending");
 
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_CLAIMS,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getAllPaymentSheets: async (api : any) => {
        try {
            //GetClaimCountEmployeeWise 
            
            const res = await lionUserManager.authenticationManager.get("/esb/PaymentSheet/" + api);
             if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_PAYMENT_SHEETS,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    sendToSap : async (api : any ,  amount : any , data : any) => {
        try { 
            const res = await lionUserManager.authenticationManager.post("/esb/Soap/"+api+"?amount="+amount+"&generatedBy="+lionUserManager.user.data.USER_ID , data);
 
            if (res.status == 201) {   
                return texts.SEND_TO_SAP_SUCCESS;
            }else {
                return texts.DEFAULT_ERROR_MESSAGE;
            }
        } catch (err) {
            if(err.response.status == 504)
                return texts.SERVER_TIME_OUT;
            else
                return texts.DEFAULT_ERROR_MESSAGE;
        }
    },


    getPaymentSheetData: async (api : any , id : any) => {
        try { 
            const res = await lionUserManager.authenticationManager.get("/esb/Claims/"+api+"?paymentSheetID="+id);
              
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_PAYMENT_SHEET_DATA,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },


    getPettyCashSummary: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/esb/SAPPettyCash/PettyCashSummary");
              
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_PETTY_CASH_SUMMARY,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    


 
})

export class DashboardActionTypes {
    static GET_ALL_TAG_CARD_DATA = 'GET_ALL_TAG_CARD_DATA';
    static GET_USER_SUMMARY = 'GET_USER_SUMMARY'; 
    static GET_MEDICAL_CLAIMS = 'GET_MEDICAL_CLAIMS'; 
    static GET_FUEL_CLAIMS = 'GET_FUEL_CLAIMS'; 
    static GET_ME_CLAIMS = 'GET_ME_CLAIMS'; 
    static GET_CLUB_CLAIMS = 'GET_CLUB_CLAIMS'; 
    static GET_PETTY_CASH_CLAIMS = 'GET_PETTY_CASH_CLAIMS'; 
    static GET_CLAIMS = 'GET_CLAIMS'; 
    static GET_PAYMENT_SHEETS = 'GET_PAYMENT_SHEETS'; 
    static GET_PAYMENT_SHEET_DATA = 'GET_PAYMENT_SHEET_DATA'; 
    static GET_PETTY_CASH_SUMMARY = 'GET_PETTY_CASH_SUMMARY'; 
    
}
