import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = {   summaryData: null  }, action: { type: DashboardActionTypes, payload: any }) => {
    switch (action.type) {
        case DashboardActionTypes.GET_USER_SUMMARY: {
            return { ...state, summaryData : action.payload }
        } 
        
        default: return state;
    }
}

export const ErrorLogRepoActions = (dispatch: any) => ({
 

    getSummaryData: async () => {
        try {
            //GetClaimCountEmployeeWise 
             const res = await lionUserManager.authenticationManager.get("/esb/Claims/GetAllPendingClaimsCounts"); 
			 console.log("res res " , res)
			 if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_USER_SUMMARY,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

 
    getSummaryData1: async () => {
        try {
            //GetClaimCountEmployeeWise 
			 const res = await lionUserManager.authenticationManager.get("/esb/Admin/GetErrorLog"); 
			 console.log("res res " , res)
            if (res.status == 200) {   
                dispatch({
                    type: DashboardActionTypes.GET_USER_SUMMARY,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },


 
})

export class DashboardActionTypes {
    static GET_USER_SUMMARY = 'GET_USER_SUMMARY'; 
    
}
