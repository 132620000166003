import React, {  Component,FunctionComponent } from 'react';
import { Table,  Typography , Row, Col,  Radio,  Spin  ,Tag, Button } from 'antd'; 
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import {  FileTextOutlined } from '@ant-design/icons';
import { Elements } from '../components/Dashboard'
import  ComponentToPrint   from './ComponentToPrint'
import ReactToPrint from "react-to-print";
import { DashboardActions } from '../repos/DashboardRepo';
import './style.css'; 
const {   Text } = Typography; 
const zeroPad = (num : any, places : any) => String(num).padStart(places, '0')

const CardMediaSpans = {
    xl: 6,
    xs: 24,
    sm: 24,
    lg: 12,
}

var code : String;
var date : String;
var amount : 0;
const getColor = (i: number) => {
    switch (i) {
        case 0: return '#4C56BA'
        case 1: return '#29275F'
        case 2: return '#F64B53'
        case 3: return '#929CB7'
        default: return '#0B3C49'
    }
}
 
const columns = [
  
  {
    title: 'Employee Number',
    dataIndex: 'emp_no',
  },
    {
      title: 'Employee Name',
	  dataIndex: 'name',
	  width: '40%',
    },
    {
      title: 'Claim ID',
      dataIndex: 'id',
	},
	{
		title: 'Claim Date',
		dataIndex: 'claimedDate',
		render: (claimedDate : any) => claimedDate.split("T")[0]
	  },
	
	
    {
      title: 'SAP Doc Ref ID',
      dataIndex: 'sapdocRefID',
    }, 
 
    {
      title: 'Total Amount',
	  dataIndex: 'amount',
	  align: 'right' as 'right',
      render: (totalAmount : any) => "Rs. "+(Math.round( totalAmount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } 
  ];
 
 
var claim_type = "";
 
class ViewPaymentSheet extends React.Component<any, any> {  
    componentRef!: Component<any, {}, any> | Element | null;
    constructor(props: any) {
        super(props); 
        this.state = {
            isVisible : false,
             loading : true,
             selectedRowKeys: [],
             value : 0
            
        }
    }
 

    async componentDidMount() { 
	  code = this.props.match.params.id.split("_")[0]; 
	  date= this.props.match.params.id.split("_")[2]; 
	    switch(code) {
		case "mc": 
		claim_type = "Medical Claim Payment Sheet";
        this._getData(0);
        break;
		case "cm": 
		claim_type = "Club Membership Claim Payment Sheet";
        this._getData(1);
        break;
		case "fc": 
		claim_type = "Fuel Claim Payment Sheet";
        this._getData(2);
        break;
		case "pc": 
		claim_type = "Petty Cash Claim Payment Sheet";
        this._getData(3);
        break;
		case "me": 
		claim_type = "ME Claim Payment Sheet";
        this._getData(4);
        break;
          }
       // await this.props.getSummaryData() 
        //this._getData(1)
      }
    
      async  _getData(status : any) {
   

        switch(status) {
          case 0:
             await this.props.getPaymentSheetData("GetMedicalClaimsByPaymentSheetId" , this.props.match.params.id.split("_")[1] );  
             this.geretateDataset("medicalClaimId" ,"MC")
             break;
           case 1:
             await this.props.getPaymentSheetData("GetClubMembersipClaimsByPaymentSheetId", this.props.match.params.id.split("_")[1]);  
             this.geretateDataset("clubMembershipClaimId","CM")
             break;
            case 2:
              await this.props.getPaymentSheetData("GetFuelClaimsByPaymentSheetId", this.props.match.params.id.split("_")[1]); 
              this.geretateDataset("fuelClaimId","FC")
              break;
            case 3:
               await this.props.getPaymentSheetData("GetPettyCashClaimsByPaymentSheetId", this.props.match.params.id.split("_")[1]); 
               this.geretateDataset("pettyCashClaimId","PC")
               break;
            case 4:
               await this.props.getPaymentSheetData("GetMEClaimsByPaymentSheetId" , this.props.match.params.id.split("_")[1]); 
               this.geretateDataset("meclaimId","ME")
               break; 
          
        }
         
        
      }

      geretateDataset(claimType : any , code : any){
        var detail : any[] = []; 
		amount = 0;
        this.props.DashboardRepo.paymentSheetData.forEach(function (data: any) { 
			amount+=data.totalAmount;
          detail.push({
            key:  data[claimType],
            id: code +'-' +  zeroPad(data[claimType], 6)  ,
            emp_no: data.employeeNumber,
            name: data.employeeName,
            amount: data.totalAmount,
            sapdocRefID: data.sapdocRefID,
			status : data.status,
			claimedDate : data.claimedDate
          });
          
	  }); 
        this.setState({claimHistory : detail , loading : false })
      }
 

    
 
   render() {
      
        return (
            <div>
                 
                 <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                 
                
                <Row>
                    <Col style={{ padding: 10, marginTop: 4 }} span={24} >
                        <Row>
                        <Col span="19" >
                        <Text style={{ fontSize: 18 }} strong>{claim_type}  </Text>
                        </Col>
                        <Col span="4" style={{ display: 'flex', justifyContent: 'flex-end'  }}>
                        <div>
                          <Row>
                        
                                <ReactToPrint
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return <Button style={{marginLeft:10}} href="#">Print</Button>;
                                }}
                                content={() => this.componentRef}
                                />
                              <div style={{ display: "none" }}> <ComponentToPrint data={this.state.claimHistory} claimType={claim_type} postedDate={date} toatlAmount={amount} claim_id={this.props.match.params.id.split("_")[1] } ref={el => (this.componentRef = el)} /></div>
                           </Row>
                            </div>
                              {/* <Button type="primary">Submit All</Button> */}
                       
                        </Col>  
                        
                         
                     </Row>
                     {this.state.claimHistory &&  <Table style={{ marginTop: 26, padding: 5  }} columns={columns} dataSource={this.state.claimHistory.reverse() }> 
                    
                    {/* <Column
                            title="Action"
                            key="index"
                            render={(value, item, index) => (
                                <div>
                                      <Link  to={`view-claim/${claimCode+"_" + value.id}`}> 
                                            <span>
                                            <a > <Tag color="#875ec4"> View</Tag> </a>
                                            </span>
                                       </Link>
                                </div>
                            )}
                            /> */}
                </Table> }
                         
                    </Col>

                    
                     
                     
                </Row>
          
                
               </Spin>
            </div>
        )
    }
}

const WrappedDashboardView = ViewPaymentSheet;
const withDashboardRepo = connect(((state: any) => state), DashboardActions)(WrappedDashboardView);
 
 
export default withRouter(withDashboardRepo as FunctionComponent); 


