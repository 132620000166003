import React, { Component } from "react";
import { Menu, Row, Col, Popover, Button, Switch as Toggler, Avatar, Input } from 'antd';
import { ShowTheLocationWithRouter, ContentPresenter, DefaultAvatar, SimpleHeader } from './components'
import { Dashboard , PaymentSheet , ViewPaymentSheet , ErrorLogPage } from './pages'
import { logOutAsLionUser, lionUserManager, userManager } from './repos/UserRepo'
import DefaultLayout from './layouts/DefaultLayout';
import {
    withRouter,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import './App.css';
import { RouteConfig } from "./route.config";
import {
    AppstoreOutlined,
    PictureOutlined,
    AuditOutlined
} from '@ant-design/icons';
import './ProtectedApp.css';
import logo from '../images/logo.png'
import { LionUser } from "user-manager";

class ProtectedApp extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { collapsed: true, };
    }

    toggle() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <DefaultLayout
                header={<ProtectedAppHeader />}
                sidebar={<ProtectedAppSidebar collapsed={this.state.collapsed} toggle={this.toggle.bind(this)} />}
                isSidebarCollapsed={this.state.collapsed}>
                {/* <FilterHeaderStrip /> */}
                <div className="breadcrumbs-container">
                    <ShowTheLocationWithRouter />
                </div>
                <Switch>
                    <Route exact path={RouteConfig.PROTECTED_MAIN_DASHBOARD}>
                        <ContentPresenter><div><Dashboard /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_PAYMENT_SHEET}>
                        <ContentPresenter><div><PaymentSheet /></div></ContentPresenter>
                    </Route>
					<Route exact path={RouteConfig.PROTECTED_ERROR_LOG}>
                        <ContentPresenter><div><ErrorLogPage /></div></ContentPresenter>
                    </Route>
                    <Route exact path={RouteConfig.PROTECTED_VIEW_PAYMENT_SHEET}>
                        <ContentPresenter><div><ViewPaymentSheet /></div></ContentPresenter>
                    </Route>
                    
                     
                    <Route path={RouteConfig.PROTECTED_ROOT}>
                        <Redirect to={{ pathname: RouteConfig.PROTECTED_MAIN_DASHBOARD }} />
                    </Route>
                </Switch>
            </DefaultLayout>
        );
    }
}

class FilterHeaderStrip extends Component<any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Col className="header-strip-container">
                <Row>
                    <Input />
                </Row>
            </Col>
        )
    }
}

class ProtectedAppHeader extends Component<any> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() { }

    render() {
        return (
            <Row justify="end" align="middle" className="main-header-container" gutter={35}>
                <Col className="main-header-notification-icon">
                    {/* <Popover content={data && <NotificationContainer data={data} />} placement="topLeft" trigger="click">
                        <Badge count={data && data.length}>
                            <div className="header-icon"><BellFilled /></div>
                        </Badge>
                    </Popover> */}
                </Col>
                <Col>
                    <Popover content={userManager.user && <UserPopover user={userManager.user} />} placement="topLeft" trigger="click">
                        <div><DefaultAvatar user={lionUserManager.user} /></div>
                    </Popover>
                </Col>
            </Row>

        );
    }
}

export class UserPopover extends Component<{ user: LionUser }>{
    render() {
        return (
            <Row className="user-popover-container" gutter={[10, 10]} align="middle" justify="center">
                {lionUserManager && <Col>
                    <Row align="middle" justify="center" className="user-popover-avatar-name">
                        <Avatar src={lionUserManager.user.data.User.imagePaths} size={70} />
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center" className="user-popover-avatar-role" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.firstName + " " + lionUserManager.user.data.User.lastName}
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center" className="user-popover-avatar-role" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.designationName}
                    </Row>
                    <Row style={{ marginTop: 1 }} align="middle" justify="center" className="user-popover-avatar-email" gutter={[10, 10]}>
                        {lionUserManager.user.data.User.email}
                    </Row>
                    <Row style={{ marginTop: 6 }} align="middle" justify="center">
                        <Button onClick={logOutAsLionUser} shape="round" className="user-popover-user-sign-out-button">Sign Out</Button>
                    </Row>
                </Col>}
            </Row>
        );
    }
}

class NotificationContainer extends Component<any>{
    render() {
        return (
            <Row style={{ width: 400 }}>
                <Col span={24}>
                    <SimpleHeader title="Notifications" />
                </Col>
                <Col className="notification-container">{

                }</Col>
            </Row>
        );
    }
}

class ProtectedAppSidebar extends Component<{ collapsed: boolean, toggle: () => void } | any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="sidebar">
                    <div className="flex-center logo"  >
                        <img src={logo} style={{ width: '100%' }}></img>
                    </div>

                    <Menu mode="inline" defaultSelectedKeys={['0']}>
                        <Menu.Item key="0">
                            <Link to={RouteConfig.PROTECTED_MAIN_DASHBOARD}>
                                <AppstoreOutlined />
                                <span>Dashboard</span>
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item key="1">
                            <Link to={RouteConfig.PROTECTED_ERROR_LOG}>
                                <AuditOutlined />
                                <span>Error Logs</span>
                            </Link>
                        </Menu.Item> */}
                        {/* <Menu.Item key="2">
                            <Link to={RouteConfig.PROTECTED_HOME}>
                                <AppstoreOutlined />
                                <span>Test</span>
                            </Link>
                        </Menu.Item> */}
 
                    </Menu>
                </div>

                <span className="sidebar-toggle-button">
                    <Toggler size="small" defaultChecked={this.props.collapsed} onChange={this.props.toggle} />
                </span>
            </React.Fragment>
        )
    }
}

// const ProtectedAppHeaderWithUser = connect((state => state), UserActionTypes)(ProtectedAppHeader);

export default withRouter(ProtectedApp);