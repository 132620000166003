import React, {  Component } from 'react';
import { Table,  Typography , Row, Col,  Radio,  Spin  ,Tag, Button } from 'antd'; 
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import {  FileTextOutlined } from '@ant-design/icons';
import { Elements } from '../components/Dashboard'
import  ComponentToPrint   from './ComponentToPrint'
import ReactToPrint from "react-to-print";
import { ErrorLogRepoActions } from '../repos/ErrorLogRepo';
import './style.css'; 
const {   Text } = Typography;
const { Column } = Table;
 
  
 
class ErrorLogPage extends React.Component<any, any> {  
    componentRef!: Component<any, {}, any> | Element | null;
    constructor(props: any) {
        super(props); 
        this.state = {
            isVisible : false,
             loading : true,
             selectedRowKeys: [],
             value : 0
            
        }
    }
 

    async componentDidMount() {
		await this.props.getSummaryData() 
		await this.props.getSummaryData1() 
         }
    
      async  _getData(status : any) {
   
	  }
 

       
    
   render() {
    const { loading, selectedRowKeys } = this.state;
     
        return (
            <div>
                 
                 <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}> 
                 
                
               </Spin>
            </div>
        )
    }
}

const WrappedErrorLogPageView = ErrorLogPage;
const withDashboardRepo = connect(((state: any) => state), ErrorLogRepoActions)(WrappedErrorLogPageView);
export default withDashboardRepo;


