import React, { Component } from 'react';
import { DashboardElementContainer } from './Container';
import { CoffeeOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Row, Col, Tooltip } from 'antd';
import { SimpleHeader } from '../Ornaments';
import { Line, Funnel, Label } from '@antv/g2plot';

const data = [
    { year: '1991', value: 3 },
    { year: '1992', value: 4 },
    { year: '1993', value: 3.5 },
    { year: '1994', value: 5 },
    { year: '1995', value: 4.9 },
    { year: '1996', value: 6 },
    { year: '1997', value: 7 },
    { year: '1998', value: 9 },
    { year: '1999', value: 13 },
];

const defaultChartColors = [
    "#4C56BA",
    "#FFB400",
    "#29275F",
    "#F64B53",
    "#929CB7",
]

const header: React.FC = (props: any) => <div>this is header</div>

interface DashboardElementProps {
    header?: Element,
    icon?: any,
    backgroundColor: string
}

interface ValueCardProps extends DashboardElementProps {
    header: any,
    icon?: any,
    backgroundColor: string
    isNegative?: boolean
}

interface GraphCardProps extends DashboardElementProps {
    value: any,
    header?: Element,
}

export class ValueCard extends Component<ValueCardProps, any>{
    render() {
        return (
            <Row className={`dashboard-card round-border ${this.props.isNegative ? 'negative' : ''}`} style={{ backgroundColor: this.props.backgroundColor }}>
                <Col className="dashboard-icon-container">
                    {
                        this.props.icon
                    }
                </Col>
                <Col>
                    <Col className="dashboard-card-header">
                        {this.props.header}
                    </Col>
                    <Col className="dashboard-card-value">{this.props.children}</Col>
                </Col>
            </Row>
        )
    }
}

export class GraphCard extends Component<GraphCardProps, any>{
    myRef: React.RefObject<any>
    chart?: Line;
    constructor(props: any) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.chart = new Line(this.myRef.current, {
            title: {
                visible: true,
                text: '曲线折线图',
            },
            description: {
                visible: true,
                text: '用平滑的曲线代替折线。',
            },
            padding: 'auto',
            forceFit: true,
            data,
            xField: 'year',
            yField: 'value',
            smooth: true,
        });
        this.chart.render()
    }

    render() {
        return (
            <DashboardElementContainer
                header={<SimpleHeader title="HI" />}
                icon={this.props.icon}
                primaryColor={this.props.backgroundColor}>
                <div style={{ height: 300 }} ref={this.myRef}></div>
            </DashboardElementContainer>
        )
    }
}

interface SummeryCardProps extends GraphCardProps {
    value: any
}

export class SummeryCard extends Component<SummeryCardProps, any>{
    myRef: React.RefObject<any>
    chart?: Funnel;
    constructor(props: any) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.chart = new Funnel(this.myRef.current, {
            padding: 'auto',
            forceFit: true,
            data: this.props.value,
            xField: 'action',
            yField: 'pv',
            transpose: true,
            color: defaultChartColors,
            percentage: {
                text: {
                    visible: false,
                    // content: "Percentage"
                },
                value: {
                    visible: true
                }
            }
        });
        this.chart.render()
    }

    render() {
        return (
            <DashboardElementContainer
                header={<SimpleHeader title="Total Summery" />}
                icon={this.props.icon}
                primaryColor={this.props.backgroundColor}>
                <div style={{ height: 300 }} ref={this.myRef}></div>
            </DashboardElementContainer>
        )
    }
}